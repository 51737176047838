// // Dropdown.tsx
// import React from 'react';
// import dropdownImage from '../assets/images/dropdown.png'

// interface DropdownProps {
//   value: string;
//   onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
// }

// const Dropdown: React.FC<DropdownProps> = ({ value, onChange }) => {
//   return (
//     <div style={{ position: 'relative', width: '100%' }}>
//       <select
//         value={value}
//         onChange={onChange}
//         style={{
//           width: '100%',
//           marginRight: '30px',
//           padding: '15px',
//           borderRadius: '50px',
//           border: '1px solid #001C5D',
//           appearance:'none', 
//           background: '#fff',

//         }}
//       >
//         {/* Placeholder option */}
//         <option value="" disabled hidden></option>
//         {/* Other options */}
//         <option value="Parent">Parent</option>
//         <option value="Sibling">Sibling</option>
//         <option value="Spouse">Spouse</option>
//         <option value="Friend">Friend</option>
//         {/* Add more options as needed */}
//       </select>
//       {/* Image icon */}
//       <img src={dropdownImage} alt="Icon" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', width: '50px', height: '70px' }} />
//     </div>
//   );
// };

// export default Dropdown;

import React from 'react';
import dropdownImage from '../assets/images/dropdown.png';

interface DropdownProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: any[];
  placeholder?: string; // Optional placeholder
}

const Dropdown: React.FC<DropdownProps> = ({ value, onChange, options, placeholder }) => {
  return (
    <div style={{ position: 'relative', width: '100%',minWidth:'280px' }}>
      <select
      className='position-relative'
        value={value}
        onChange={onChange}
        style={{
          // position:'absolute',
          // left:'0',
          width: '100%',
          minWidth:'280px',         
          paddingLeft: '30px',
          paddingRight:'30px',
          borderRadius: '50px',
          border: '1px solid #001C5D',
          appearance: 'none',    
          height:'44px',
          backgroundColor:'transparent',
          zIndex:2
        }}
      >
        {/* Optional placeholder */}
        {placeholder && <option value="" disabled hidden>{placeholder}</option>}
        {/* Map over options array to generate options */}
        {options.map((option:any, index:any) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <img
        src={dropdownImage}
        alt="Icon"
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '50px',
          height: '44px',
          zIndex:1
        }}
      />
    </div>
  );
};

export default Dropdown;
