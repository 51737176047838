import { envConfig } from '../config/env_config';
export const Footer = () => {
    return (
    // <div className='footer adli-container'>
    //         <div>
    //             <p> US3950 03/2024 Copyright  Eisai Co., Ltd. All Right Reserved.</p>
    //             <p>ADLi is powered by OtisHealth.</p>
    //         </div>
    //         <div className=''>
    //             <p> Privacy Statement | Terms & Conditions | Contact Us</p>
    //         </div>
    // </div>
    <>
        <div className='row1 footer web_menu d-flex justify-content-center'  >

<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 web_menu' style={{fontSize:'16px', lineHeight:'21.6px', fontWeight: '400px', textAlign:'left', paddingLeft:'80px'}}>
    <p>US3950 08/2024 Copyright © Eisai, Inc. All rights reserved.<br/>
    ADLi is powered by OtisHealth.</p>
 </div>  
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 web_menu p mt-2' style={{textAlign:'right', color:'#FFFF',paddingRight:'80px'}}>
<a href={envConfig.privacyUrl} className='px-2' style={{ fontSize:'16px', lineHeight:'21.6px', fontWeight: '400px', cursor:'pointer', textDecoration:'underline', color: 'white'}} target="_blank">Privacy Policy</a><span>|</span>
<a href={envConfig.termsConditions} className='px-2' style={{fontSize:'16px', lineHeight:'21.6px', fontWeight: '400px', cursor:'pointer', textDecoration:'underline', color: 'white'}} target="_blank" >Terms and Conditions</a><span>|</span>
<a href={envConfig.contactUs} className='px-2' style={{ fontSize:'16px', lineHeight:'21.6px', fontWeight: '400px', cursor:'pointer',textDecoration:'underline', color: 'white'}}  target="_blank" >Contact Us</a>
</div>  
    </div>
        <div className="mobile-footer mobile-view">
            
<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-2 mobile_menu text-center' style={{fontSize:'12px', lineHeight:'16px',textAlign:'center'}}>
<p className="p-2" style={{fontSize:'12px', lineHeight:'16px',textAlign:'center',marginBottom:0}}>US3950 08/2024 Copyright © Eisai, Inc.</p>
<p className='px-2' style={{fontSize:'12px', lineHeight:'16px',textAlign:'center',marginBottom:0}}>All rights reserved.ADLi is powered by OtisHealth.</p>
</div>  

<div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 pb-2 mobile_menu text-center' style={{textAlign:'left', color:'#FFFF'}}>
    <a href={envConfig.privacyUrl} className='px-2' style={{ fontSize:'12px', lineHeight:'21.6px', fontWeight: '400px', cursor:'pointer', textDecoration:'underline', color: 'white'}} target="_blank" >Privacy Policy</a><span>|</span>
    <a href={envConfig.termsConditions} className='px-2' style={{ fontSize:'12px', lineHeight:'21.6px', fontWeight: '400px', cursor:'pointer', textDecoration:'underline', color: 'white'}} target="_blank" >Terms and Conditions</a><span>|</span>
    <a href={envConfig.contactUs} className='px-2' style={{ fontSize:'12px', lineHeight:'21.6px', fontWeight: '400px', cursor:'pointer', textDecoration:'underline', color: 'white'}} target="_blank" >Contact Us</a>
</div>    
        </div>
    </>
  )};