import React, { useState, useEffect, useContext} from 'react';
import Header from '../../../component/Header/Header';
import { Footer } from '../../../component/Footer';
import welcomePatientImage from '../../../assets/images/4_Home_Patient_3.0.png';
import logo from '../../../assets/images/App_logo.png';
import AppServices from '../../../services/appServices';
import { useNavigate } from 'react-router-dom';
import { APIEndpoint } from '../../../config/apiendpoint_config';
import PrimaryButton from '../../../component/PrimaryButton';
import { UserContext } from '../../../context/UserContext';
import waveHand from '../../../assets/Animations/Eisai-ADL-App-03_wave.gif'



const CaregiverWelcomeScreen = () => {
  const navigate = useNavigate();
  const _appServices = new AppServices();
  const [loading, setLoading] = useState(false)
  const [welcomeData, setWelcomeData] = useState<any>();
  const { loggedInUserDetails } = useContext(UserContext)
  const handleStartPress = async () => {
    navigate('/welcome/confirm');
    _appServices.logData({
      Severity: 'Info',
      Activity: 'User clicked on Lets Get Started Button from Caregiver Welcome Screen',
      ActivityStatus: 'Success',
      ActivityResponse: 'Page View',
      Operation: 'User Activity',
    })
  };

  useEffect(() => {
    setLoading(true)
    _appServices.getData(APIEndpoint.welcomeApi).then((res:any) => {
      if ((res.status == 200 || res.status == 202) && res.success) {
        let _res = res.data[0];
        setWelcomeData(_res);
        setLoading(false)
      }
    }, err => {
      setLoading(false)
      console.log(err.message)
    });
  }, [])
  return (
    <div className='app-bg-color'>
      {/* <div className="row">
            <Header/>
        </div> */}
      <div className="row d-flex justify-content-start align-items-center px-2 mx-2 px-md-5 mx-md-5" style={{ minHeight: '75vh' }} >
        <div className='col-12 col-md-6 order-md-1 order-2'>
          <div className='d-flex justify-content-md-start justify-content-start flex-column flex-md-row align-items-center align-items-md-baseline '>
            <h1 className='Welcometitle'>Welcome to </h1>
            <img style={{ width: '157px', marginLeft: '20px' }} src={logo} alt="" />
          </div>
          {
           welcomeData?.invitedUserTypeId == 1 ?
              <p className='mt-3' style={{ fontFamily: 'Satoshi-Regular', fontSize: '19px', lineHeight: '25.65px', color: '#001C5D', fontWeight: '400px' }}>
                {/* When a family member or friend is living with Alzheimer’s disease (AD), you can provide help and support.
                That’s why {welcomeData?.patientInfo?.firstName} {welcomeData?.patientInfo?.lastName} has invited you to enroll in ADLi.
                With ADLi, from time to time, you may be asked to answer
                questions about {welcomeData?.patientInfo?.firstName} {welcomeData?.patientInfo?.lastName}'s life with AD. Your
                answers may help Dr. {welcomeData?.providerInfo?.firstName} {welcomeData?.providerInfo?.lastName} manage {welcomeData?.patientInfo?.firstName} {welcomeData?.patientInfo?.lastName}'s activities of daily living (ADL). */}
                When a family member or friend is living with Alzheimer's disease (AD), you can provide help and support. 
                That's why {welcomeData?.patientInfo?.firstName} {welcomeData?.patientInfo?.lastName} has invited you to enroll in ADLi.
                With ADLi, from time to time, you may be asked to answer questions about {welcomeData?.patientInfo?.firstName} {welcomeData?.patientInfo?.lastName} life with mild cognitive impairment (MCI) or early AD.
                Your answers may help Dr. {welcomeData?.providerInfo?.firstName} {welcomeData?.providerInfo?.lastName} evaluate {welcomeData?.patientInfo?.firstName} {welcomeData?.patientInfo?.lastName} daily functioning.
              </p>
              :
              <p className='mt-3' style={{ fontFamily: 'Satoshi-Regular', fontSize: '19px', lineHeight: '25.65px', color: '#001C5D', fontWeight: '400px' }}>
                {/* When a family member or friend is living with Alzheimer's disease (AD), you can provide
                help and support. That's why Dr. {welcomeData?.providerInfo?.firstName} {welcomeData?.providerInfo?.lastName + ' '}
                has invited you to enroll in ADLi. With ADLi, from time to time you may be asked to answer questions about {welcomeData?.patientInfo?.firstName} {welcomeData?.patientInfo?.lastName}'s life with
                AD. Your answers may help Dr. {welcomeData?.providerInfo?.firstName} {welcomeData?.providerInfo?.lastName} manage {welcomeData?.patientInfo?.firstName} {welcomeData?.patientInfo?.lastName}'s activities of daily living (ADL). */}
              When a family member or friend is living with Alzheimer's 
              disease (AD), you can provide help and support. That's why 
              Dr. {welcomeData?.providerInfo?.firstName} {welcomeData?.providerInfo?.lastName} has invited you to enroll in ADLi. With ADLi, from
              time to time, you may be asked to answer questions about {welcomeData?.patientInfo?.firstName} {welcomeData?.patientInfo?.lastName} life with mild cognitive impairment (MCI) or early AD. 
              Your answers may help Dr. {welcomeData?.providerInfo?.firstName} {welcomeData?.providerInfo?.lastName} evaluate {welcomeData?.patientInfo?.firstName} {welcomeData?.patientInfo?.lastName} daily functioning.
              </p>

          }
          <div className="row mt-5">
            <PrimaryButton text="Let's Get Started" loading={loading} handleSubmit={handleStartPress} />
            {/* <button className='button-primary '>Let's Get Started</button> */}
          </div>
        </div>
        <div className='col-12 col-md-6 order-md-2 order-1'><img style={{ width: '100%' }} src={waveHand} alt="" /></div>
      </div>
      {/* <div className="row">
            <Footer/>
        </div> */}
    </div>
  )
}

export default CaregiverWelcomeScreen