import { Formik } from "formik";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AppServices from "../../../../services/appServices";
import { APIEndpoint } from "../../../../config/apiendpoint_config";
import Swal from "sweetalert2";
import PrimaryButton from "../../../../component/PrimaryButton";


const ChangePassword = () => {

  const navigate = useNavigate();
  const _appServices = new AppServices()

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleFormSubmit = async(values: any, resetForm: any, setSubmitting: any) => {
    console.log(values);
    setSubmitting(true);
    let postJson = {
      "oldPassword": values.oldPassword,
      "password": values.newPassword
    };
    try{
      const res = await _appServices.patchData(APIEndpoint['changePassword'], postJson)
      if ((res.status === 200 || res.status === 202) && res.success) {
        // handle success
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res?.message + ' Please, Login again' || "Data updated successfully. Please, Login again",
        }).then((result:any)=>{
          if(result.isConfirmed){
            _appServices.signOut()
          }
        })
        setSubmitting(false)
      } else {
        // handle failure
        let errMessage = _appServices.getErrorMessage(res)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
        setSubmitting(false)
      }
    }catch(error){
      console.log(error)
      let errMessage = _appServices.getErrorMessage(error)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string()
      .required("New Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{10,}$/,
        "Password must contain at least 10 characters including a symbol, upper and lowercase letters, and a number."
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Confirm Password and New Password did not match")
      .required("Confirm Password is required"),
  });

  return (
    <div>
      <div className='profile-form mt-5'>

        <div className='row mt-5'>
          <h5 className='header'>Change Password</h5>
        </div>

        <div className='change form'>
        <Formik
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            handleFormSubmit(values, resetForm, setSubmitting);
          }}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mt-5 mb-4">
                <Form.Control
                  className="input-box2"
                  type={showPassword ? "text" : "password"}
                  name="oldPassword"
                  placeholder="Old Password"
                  value={values.oldPassword}
                  onChange={handleChange}
                  isInvalid={!!errors.oldPassword}
                />
                {showPassword ? (
                  <i
                    className="fas fa-eye"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      position: "absolute",
                      right: '100px',
                      // top:'50%',
                      transform: "translateY(30%)",
                      padding: "5px",
                      width: "35px",
                      borderRadius: "50%",
                      marginRight: 30,
                      alignItems: "center",
                    }}
                  ></i>
                ) : (
                  <i
                    className="fas fa-eye-slash"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      position: "absolute",
                      right: '100px',
                      // top:'50%',
                      transform: "translateY(30%)",
                      padding: "5px",
                      width: "35px",
                      borderRadius: "50%",
                      marginRight: 30,
                      alignItems: "center",
                    }}
                  ></i>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.oldPassword}
                </Form.Control.Feedback>
              </Row>

              <Row className="mb-4">
                <Form.Control
                  className="input-box2"
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  placeholder="New Password"
                  value={values.newPassword}
                  onChange={handleChange}
                  isInvalid={!!errors.newPassword}
                />
                {showNewPassword ? (
                  <i
                    className="fas fa-eye"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    style={{
                      position: "absolute",
                      right: '100px',
                      // top:'50%',
                      transform: "translateY(30%)",
                      padding: "5px",
                      width: "35px",
                      borderRadius: "50%",
                      marginRight: 30,
                      alignItems: "center",
                    }}
                  ></i>
                ) : (
                  <i
                    className="fas fa-eye-slash"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    style={{
                      position: "absolute",
                      right: '100px',
                      // top:'50%',
                      transform: "translateY(30%)",
                      padding: "5px",
                      width: "35px",
                      borderRadius: "50%",
                      marginRight: 30,
                      alignItems: "center",
                    }}
                  ></i>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.newPassword}
                </Form.Control.Feedback>
              </Row>

              <Row className="mb-4">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  className="input-box2"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  isInvalid={!!errors.confirmPassword}
                />
                {showConfirmPassword ? (
                  <i
                    className="fas fa-eye"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    style={{
                      position: "absolute",
                      right: '100px',
                      // top:'50%',
                      transform: "translateY(30%)",
                      padding: "5px",
                      width: "35px",
                      borderRadius: "50%",
                      marginRight: 30,
                      alignItems: "center",
                    }}
                  ></i>
                ) : (
                  <i
                    className="fas fa-eye-slash"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    style={{
                      position: "absolute",
                      right: '100px',
                      // top:'50%',
                      transform: "translateY(30%)",
                      padding: "5px",
                      width: "35px",
                      borderRadius: "50%",
                      marginRight: 30,
                      alignItems: "center",
                    }}
                  ></i>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Row>

              <Row className="mt-5 mb-3">
                <Col>
                <PrimaryButton
                      handleSubmit={async () => handleSubmit()}
                      loading={isSubmitting}
                      text="Change Password"
                      disabled={!isValid}
                    />
                </Col>
                <Col></Col>
              </Row>
            </Form>
          )}
        </Formik>
        </div>

      </div>
    </div>
  )
}

export default ChangePassword