import React, { useContext, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../../component/Header/Header';
import { Footer } from '../../component/Footer';
import CustomInput from '../../component/CustomInput';
import Dropdown from '../../component/Dropdown';
import { SECONDRY_COLOR, TERTIARY_COLOR } from '../../colors';
import walkthroughImage1 from '../../assets/Animations/Eisai-ADL-App-01_opening.gif';
import walkthroughImage2 from '../../assets/images/Walkthrough-image1.jpg';
import walkthroughImage3 from '../../assets/images/Walkthrough-image2.jpg';
import walkthroughImage4 from '../../assets/images/Walkthrough-image3.jpg';
import walkthroughImage5 from '../../assets/images/Walkthrough-image4.jpg';
import walkthroughImage6 from '../../assets/images/Walkthrough-image5.jpg';
import walkthroughImage7 from '../../assets/images/Walkthrough-image6.jpg';
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { envConfig } from '../../config/env_config';

const WalkthroughScreen: React.FC = () => {
    const { userType, userTypeId } = useContext(UserContext);
    const [currentSlide, setCurrentSlide] = useState(0);
    const navigate = useNavigate()
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        beforeChange: (oldIndex: number, newIndex: number) => {
            setCurrentSlide(newIndex);
        },
        customPaging: function (index: number) {
            const activeColor = '#7E7E7E';
            const inactiveColor = '#C3C3C3';
            return (
                <div
                    style={{
                        width: '11px',
                        height: '11px',
                        borderRadius: '50%',
                        display: 'inline-block',
                        margin: '0 5px',
                        // marginBottom: '50px',
                        backgroundColor: index === currentSlide ? activeColor : inactiveColor,
                    }}
                ></div>
            );
        }
    };

    const handleSkip = () => {
        navigate('/user/home')
    }

    const handlePrivacyPolicyClick = () => {
        window.open(envConfig.privacyUrl, "_blank");
      };
      
      const handleTermsConditionsClick = () => {
        window.open(envConfig.termsConditions, "_blank");
      };
      
      const handleFAQClick = () => {
        window.open(envConfig.faq, "_blank");
      };

    return (
        <div>
            <div className="row">
                <Header />
            </div>
            <div className="row d-flex justify-content-center align-items-center px-2 mx-2 px-md-5 mx-md-5 p-5" style={{ minHeight: `${window.innerHeight-130}px`,marginTop:'40px'}}>
                <div className="col-12 col-md-6 order-md-1 order-2 d-flex flex-column justify-content-center align-items-center">
                    <div style={{ width: '100%' }}>
                        <h4 style={{ color: TERTIARY_COLOR, marginBottom: '50px', textAlign: 'right', paddingTop: '20px',cursor:'pointer' }} onClick={handleSkip} >SKIP</h4>
                        <h5 style={{ fontFamily: 'Satoshi-Bold', fontWeight: '500px', fontSize: '35px', marginBottom: '30px', color: SECONDRY_COLOR, marginLeft: '8px', width: '100%', textAlign: 'center' }}>Walk-through</h5>
                        <Slider {...settings}>
                            {/* <div className="d-flex flex-column justify-content-center align-items-center"> */}
                                {/* <div style={{ border: '1px solid #0095B9', height: '100%', width: '322px', borderRadius: '15px', marginBottom: '20px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '50px', marginTop: '10px'}}>
                                        <div style={{ marginLeft: '10px' }}>
                                            <MdOutlineKeyboardArrowLeft
                                                color='#0095B9'
                                                size={30}
                                            />
                                        </div>
                                        <div>
                                            <p style={{ marginLeft: '70px', color: TERTIARY_COLOR, fontSize: '16px', fontWeight: 'bold',  marginTop: '10px' }}>My Account</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={()=> navigate('/user/myaccount')}
                                     >
                                        <div>
                                            <p style={{ marginLeft: '30px', color: SECONDRY_COLOR, fontSize: '16px', fontWeight: 'bold' }}>Profile</p>
                                        </div>
                                        <div style={{ marginRight: '10px' }}>
                                            <MdOutlineKeyboardArrowRight
                                                color='#001C5D'
                                                size={30}
                                            />
                                        </div>
                                    </div>
                                    {userTypeId == 1 ? (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={()=> navigate('/user/myaccount')}>
                                            <div>
                                                <p style={{ marginLeft: '30px', color: SECONDRY_COLOR, fontSize: '16px', fontWeight: 'bold' }}>Manage Caregivers</p>
                                            </div>
                                            <div style={{ marginRight: '10px' }}>
                                                <MdOutlineKeyboardArrowRight
                                                    color='#001C5D'
                                                    size={30}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={()=> navigate('/user/myaccount')}>
                                            <div>
                                                <p style={{ marginLeft: '30px', color: SECONDRY_COLOR, fontSize: '16px', fontWeight: 'bold' }}>Manage Patient</p>
                                            </div>
                                            <div style={{ marginRight: '10px' }}>
                                                <MdOutlineKeyboardArrowRight
                                                    color='#001C5D'
                                                    size={30}
                                                />
                                            </div>
                                        </div>
                                    )

                                    }

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={()=> navigate('/user/myaccount')}>
                                        <div>
                                            <p style={{ marginLeft: '30px', color: SECONDRY_COLOR, fontSize: '16px', fontWeight: 'bold' }}>Change Password</p>
                                        </div>
                                        <div style={{ marginRight: '10px' }}>
                                            <MdOutlineKeyboardArrowRight
                                                color='#001C5D'
                                                size={30}
                                            />
                                        </div>
                                    </div>
                                    {userTypeId == 2 &&
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={()=> navigate('/user/myaccount')}>
                                            <div>
                                                <p style={{ marginLeft: '30px', color: SECONDRY_COLOR, fontSize: '16px', fontWeight: 'bold' }}>Switch Patient</p>
                                            </div>
                                            <div style={{ marginRight: '10px' }}>
                                                <MdOutlineKeyboardArrowRight
                                                    color='#001C5D'
                                                    size={30}
                                                />
                                            </div>
                                        </div>
                                    }
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={handlePrivacyPolicyClick}>
                                            <div>
                                                <p style={{ marginLeft: '30px', color: SECONDRY_COLOR, fontSize: '16px', fontWeight: 'bold' }}>Privacy Policy</p>
                                            </div>
                                            <div style={{ marginRight: '10px' }}>
                                                <MdOutlineKeyboardArrowRight
                                                    color='#001C5D'
                                                    size={30}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}  onClick={handleTermsConditionsClick}>
                                            <div>
                                                <p style={{ marginLeft: '30px', color: SECONDRY_COLOR, fontSize: '16px', fontWeight: 'bold' }}>Terms & Conditions</p>
                                            </div>
                                            <div style={{ marginRight: '10px' }}>
                                                <MdOutlineKeyboardArrowRight
                                                    color='#001C5D'
                                                    size={30}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}  onClick={handleFAQClick}>
                                            <div>
                                                <p style={{ marginLeft: '30px', color: SECONDRY_COLOR, fontSize: '16px', fontWeight: 'bold' }}>FAQs</p>
                                            </div>
                                            <div style={{ marginRight: '10px' }}>
                                                <MdOutlineKeyboardArrowRight
                                                    color='#001C5D'
                                                    size={30}
                                                />
                                            </div>
                                        </div>
                                </div> */}
                                {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px',  marginBottom:'20px'}}>
                                    <div>
                                        <FaRegCheckCircle
                                            color='#c5007e'
                                            size={24}
                                        />
                                    </div>
                                    <div>
                                        
                                    </div>
                                    <div>
                                        <p style={{ marginLeft: '10px', fontSize: '20px', lineHeight: '27px', fontWeight: '400px', marginRight: '150px' }}>See your Profile</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom:'20px'}}>
                                    <div>
                                        <FaRegCheckCircle
                                            color='#c5007e'
                                            size={24}
                                        />
                                    </div>
                                    <div>
                                        <p style={{ marginLeft: '10px', fontSize: '20px', lineHeight: '27px', fontWeight: '400px',  marginRight: '100px' }}>Manage Connections</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <FaRegCheckCircle
                                            color='#c5007e'
                                            size={24}
                                        />
                                    </div>
                                    <div>
                                        <p style={{ marginLeft: '10px', fontSize: '20px', lineHeight: '27px', fontWeight: '400px', marginRight: '85px' }}>Change Your Password</p>
                                    </div>
                                </div> */}

                            {/* </div> */}
                            <div >
                                <img className='walkthrough-img' src={walkthroughImage2} alt="" />
                            </div>
                            <div>
                                <img className='walkthrough-img'  src={walkthroughImage3} alt="" />
                            </div>
                            <div>
                                <img className='walkthrough-img'  src={walkthroughImage4} alt="" />
                            </div>
                            <div>
                                <img className='walkthrough-img'  src={walkthroughImage5} alt="" />
                            </div>
                            <div>
                                <img className='walkthrough-img'  src={walkthroughImage6} alt="" />
                            </div>
                            <div>
                                <img className='walkthrough-img'  src={walkthroughImage7} alt="" />
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className="col-12 col-md-6 order-md-2 order-1 web-view">
                    <img style={{ width: '100%' }} src={walkthroughImage1} alt="Animation" />
                </div>
            </div>
            <div className="row">
                <Footer />
            </div>
        </div>
    );
};

export default WalkthroughScreen;
