import React, { useContext, useEffect, useState } from "react";
import womenImg from "../../../assets/images/4_Home_Patient_3.0.png";
import chessImage from "../../../assets/images/5_Home_Caregiver_3.0.png";
import { Footer } from "../../../component/Footer";
import AuthHeader from "../../../component/AuthHeader";
import { UserContext } from "../../../context/UserContext";
import { APIEndpoint } from "../../../config/apiendpoint_config";
import AppServices from "../../../services/appServices";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import brainTip from "../../../assets/images/BrainTip.png";
import brainPinkImg from "../../../assets/images/adliPinkIcon.png";
import { SECONDRY_COLOR, TERTIARY_COLOR } from "../../../colors";
import Swal from "sweetalert2";
import patientHomeAnimation from '../../../assets/Animations/Eisai-ADL-App-04_bird.gif';
import caregiverHomeAnimation from '../../../assets/Animations/Eisai-ADL-App-05_chess.gif';

interface BrainTip {
  id: number;
  uuid: string;
  title: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  status: number;
}

const Home = () => {
  const _appServices = new AppServices();
  const {
    userTypeId,
    brainTipsDetails,
    setBrainTipsDetails,
    countdownData,
    setCountdownData,
    assesmentsDetails,
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [pendingAssessment, setPendingAssessment] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    },
    customPaging: function (index: number) {
      const activeColor = "#7E7E7E";
      const inactiveColor = "#C3C3C3";
      return (
        <div
          style={{
            width: "11px",
            height: "11px",
            borderRadius: "50%",
            display: "inline-block",
            marginBottom: "0px",
            marginRight: "35px",
            backgroundColor:
              index === currentSlide ? activeColor : inactiveColor,
          }}
        ></div>
      );
    },
  };

  const padWithZeros = (number:any, length:any) => {
    let str = String(number);
    while (str.length < length) {
      str = '0' + str;
    }
    if(str.startsWith('-')){
      str = '00'
    }
    return str;
  };

  useEffect(() => {
    console.log("entering into useeffect from home", brainTipsDetails.length)
    _appServices.getData(`${APIEndpoint.getBrainTips}`).then(
          (res) => {
            console.log("response", res)
            if (res.status === 200 && res.success) {
              if (!brainTipsDetails || brainTipsDetails.length == 0) {
                const braintipsData = res.data[0]?.braintips || [];
                setBrainTipsDetails(braintipsData);
              }
              const assessments = res.data[0]?.assessments || [];
              setPendingAssessment(assessments.length === 0);
              if (assessments.length > 0) {
                const countdownInfo = assessments[0];
                console.log("Countdown Data:", countdownInfo);
                setCountdownData(countdownInfo);
                if(countdownInfo?.countdown <0){
                    setCountdownData((prevState:any)=>({... prevState,countdown:0}))
                }
              }
              else{
                setCountdownData(null)
              }
            }
            setIsLoading(false);
          },
          async (err) => {
            console.log("err", err);
            setIsLoading(false);
          }
        );

  }, [brainTipsDetails]);

  return (
    <div style={{ marginTop: "120px" }} className="app-bg-color">
      <div className="container mt-5">
        <div>
          <h1 className="title">Living with Alzheimer’s Disease (AD)</h1>
        </div>

        <div className="row">
          {userTypeId == 1 ? (
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <img src={patientHomeAnimation} style={{ height: "100%", width: "100%" }} />
            </div>
          ) : (
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <img src={caregiverHomeAnimation} style={{ height: "100%", width: "100%" }} />
            </div>
          )}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-5">
            <div className="braintip-box mt-5">
              <div className="p-md-3 mt-md-3">
                <div className="braintip-img d-md-block d-none">
                  <img
                    src={brainPinkImg}
                    alt="Brain Tips"
                    style={{ width: "60px", height: "60px" }}
                  />
                </div>
                <div className="braintip-img d-flex d-md-none">
                  <img
                    src={brainPinkImg}
                    alt="Brain Tips"
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
                <div className="braintip-slider-box">
                  <h2
                    style={{
                      color: TERTIARY_COLOR,
                      fontSize: "35px",
                      fontWeight: "500px",
                    }}
                  >
                    Brain Tip
                  </h2>
                  <Slider {...settings}>
                    {brainTipsDetails.map(
                      (braintip: BrainTip, index: number) => (
                        <div key={index}>
                          <h3
                            style={{
                              fontSize: "17px",
                              fontWeight: "400px",
                              lineHeight: "22.95px",
                              color: SECONDRY_COLOR,
                            }}
                          >
                            {`${braintip.title} - ${braintip.description}`}
                          </h3>
                        </div>
                      )
                    )}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {countdownData == null ? (
        <div>
          <h4 className="nullData text-center my-5">No Pending Assessment</h4>
        </div>
      ) : (
        <div>
          <div className="countdown-wrapper shadow-lg p-5">
            <div className="row">
              <div className="col-3">
                <div className="d-flex justify-content-end align-items-center mt-2">
                  <div className="daysTimer">
                    <h1 className="title text-center">
                      {countdownData?.countdown
                        ?.toString()
                        .padStart(2, "0")
                        .charAt(0)}
                    </h1>
                  </div>
                  <div className="daysTimer">
                    <h1 className="title text-center">
                      {countdownData?.countdown
                        ?.toString()
                        .padStart(2, "0")
                        .charAt(1)}
                    </h1>
                  </div>
                </div>
              </div>

              <div className="col-9">
                <div className="ml-5">
                  <div>
                    <h1 className="nullData">
                      Countdown to the next Assessment!
                    </h1>
                  </div>
                  <div>
                    <h5 className="content">{`${countdownData?.unit} until it’s time to answer the questionnaire for ${countdownData?.patientFirstName} ${countdownData?.patientLastName}.`}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* mobile */}
          <div className="mobile-view p-1">
            <div className="row">
              <div className="col-4">
                <div className="d-flex justify-content-end align-items-center mt-2">
                  <div className="daysTimer-mobile">
                    <h1 className="contentTitle text-center">
                      {countdownData?.countdown
                        ?.toString()
                        .padStart(2, "0")
                        .charAt(0)}
                    </h1>
                  </div>
                  <div className="daysTimer-mobile">
                    <h1 className="contentTitle text-center">
                      {countdownData?.countdown
                        ?.toString()
                        .padStart(2, "0")
                        .charAt(1)}
                    </h1>
                  </div>
                </div>
              </div>

              <div className="col-8">
                <div className="ml-5">
                  <div>
                    <h1 className="mobile-subTitle">
                      Countdown to the next Assessment!
                    </h1>
                  </div>
                  <div>
                    <h5 className="mobile-content">{`${countdownData?.unit} until it’s time to answer the questionnaire for ${countdownData?.patientFirstName} ${countdownData?.patientLastName}.`}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
