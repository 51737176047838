import React, { useContext, useEffect, useState } from "react";
import assessImg from "../../../../assets/images/6_Assessment_4.0.png";
import AppServices from "../../../../services/appServices";
import { UserContext } from "../../../../context/UserContext";
import PrimaryButton from "../../../../component/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { SECONDRY_BTN_COLOR, TERTIARY_COLOR } from "../../../../colors";
import { APIEndpoint } from "../../../../config";
import currentAssessAni from '../../../../assets/Animations/Eisai-ADL-App-06_steam.gif'
import { ClipLoader } from "react-spinners";

const Current = ({ currentAssismentDetails, assesmmentQuestions }) => {
  const _appServices = new AppServices();
  const [quesLoading, setQuesLoading] = useState(false);
  const {
    assesmentsDetails,
    assesmmentQuestion,
    setAssementCount,
    assessmentCount,
    messageCount,
    setAssesmmentQuestion,
  } = useContext(UserContext);
  // const [currentAssismentDetails, setCurrentAssismentDetails] = useState(null);
  // const [isRequesting, setIsRequesting] = useState(false);
  const navigate = useNavigate();

  const getCurrentAssessmentDetails = () => {
    setQuesLoading(true)
      _appServices.getData(`${APIEndpoint.assessments}`).then(
        (res) => {
          if (res.status == 200 && res.success) {
            let _res = res.data;
           console.log("currentData",_res)
           
           setQuesLoading(false)
          }
        },
        (err) => {
          setQuesLoading(false)
          console.log("err", err);
        }
      );

  };

  useEffect(()=>{
    getCurrentAssessmentDetails()
  },[])

  // useEffect(() => {
  //     setAssementCount(0);
  //     if (assessmentCount == 1) {
  //     //   if(Platform.OS == 'ios'){
  //     //   PushNotificationIOS.setApplicationIconBadgeNumber(messageCount);
  //     //   }
  //     }

  //     if(assesmentsDetails){
  //       setIsRequesting(false)
  //       setCurrentAssismentDetails(assesmentsDetails['currentAssessments']?.length > 0 ? assesmentsDetails['currentAssessments']: null);
  //     } else {
  //       setIsRequesting(true)
  //     }
  //   }, [currentAssismentDetails])

  const handleStartPress = () => {
    let questionniareData = {
      assismentId: currentAssismentDetails[0]?.patientAssessmentsUid
    };
    // try {
    //   if (
    //     currentAssismentDetails[0]?.patientAssessmentsUid &&
    //     assesmmentQuestions == undefined
    //   ) {
    //     setQuesLoading(true);
    //     _appServices
    //       .getData(
    //         `${APIEndpoint.assessments}/${currentAssismentDetails[0]?.patientAssessmentsUid}`
    //       )
    //       .then(
    //         (res) => {
    //           if (res.status == 200 && res.success) {
    //             let assesmmentQuestion = res.data[0];
    //             setAssesmmentQuestion(assesmmentQuestion);
    //             setQuesLoading(false);
    //             questionniareData["assesmmentQuestionList"] =
    //               assesmmentQuestion;
    //             navigate("/user/test");
    //           }
    //         },
    //         (err) => {
    //           console.log("Something went wrong");
    //         }
    //       );
    //   } else {
    //     navigate("/user/test");
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
    navigate('/user/test', { state: questionniareData });

    //   console.log("dfdfdfd",{assismentId:currentAssismentDetails[0]?.patientAssessmentsUid,assesmmentQuestionList:assesmmentQuestion})

    // _appServices.logData({
    //   Severity: 'Info',
    //   Activity: 'User Begin the Assessment',
    //   ActivityStatus: 'Success',
    //   ActivityResponse: 'Page View',
    //   Operation: 'User Activity',
    // });
  };

  return (
    <div className="app-bg-color">
      <div className="row app-bg-color">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <img src={currentAssessAni} className="fit-img" />
        </div>

       {/* {quesLoading?<ClipLoader color={TERTIARY_COLOR} size={50}/>: */}
       
       <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-5 content-scroll">
       {currentAssismentDetails?.length > 0 ? (
         <>
           <h5 className="contentTitleAssessment mb-4">Assessment</h5>

           <p className="contentsubTitleAssessment">
             The goal of this questionnaire is to evaluate your or your loved one's ability to
             carry out Activities of Daily Living (ADLs) independently. Your
             answers can help the doctor monitor functioning and identify any
             changes. That’s why Dr.{" "}
             {currentAssismentDetails[0]?.providerFirstName}{" "}
             {currentAssismentDetails[0]?.providerLastName} has asked that
             you answer this series of ADL questions
             {` ${currentAssismentDetails[0]?.frequencyName?.toLowerCase()}`}
             .
           </p>

           <p className="subTitleAssessment mt-4">
             Once you start the questionnaire, please finish it within 24
             hours.
           </p>

           <div className="mt-5">
             <PrimaryButton
               text={
                 currentAssismentDetails[0]?.isStarted ? "Resume" : "Begin"
               }
               loading={false}
               handleSubmit={handleStartPress}
             />
           </div>
         </>
       ) : (
         <div className="d-flex justify-content-center align-items-center">
           <h5 className="contentTitleAssessment" style={{marginTop: '50px'}}>No assessment found</h5>
         </div>
       )}
     </div>
     {/* } */}
      </div>
    </div>
  );
};

export default Current;
